import { Box, TablePagination, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import MuiModal from "../../../components/MuiModal";
import { approveClient, deleteClient, getNewClients } from "./Helper";
import { useQueryParams } from "../../../hooks";

const PendingClientList = () => {
  const context = useOutletContext();
  const [clients, setClients] = useState([]);
  const { getParam, setMultiParam } = useQueryParams();
  const currentPage = getParam("page");
  const currentLimit = getParam("pageSize");
  const [clientsPage, setClientsPage] = useState(parseInt(currentPage) || 0);
  const [clientsLimit, setClientsLimit] = useState(parseInt(currentLimit) || 10);
  const [selectedDetails, setSelectDetails] = useState(null);
  const [clientsTotal, setClientsTotal] = useState(0);
  const [currentApproval, setCurrentApproval] = useState(true);
  const [toggle, setToggle] = useState(true);
  const handleChangePage = (event, newPage) => {
    fetchData(newPage, clientsLimit);
    setClientsPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setClientsLimit(parseInt(event.target.value, 10));
    setClientsPage(0);
    fetchData(0, parseInt(event.target.value, 10));
  };

  const fetchData = (page, limit) => {
    getNewClients(page, limit)
      .then((data) => {
        setClients(data.data.clients.rows);
        setClientsTotal(data.data.clients.totalItems);
      })
      .catch((err) => console.log(err));
  };

  const navigate = useNavigate();
  useEffect(() => {
    context(["Pending Approvals", "Client Approvals"]);
    getNewClients(clientsPage, clientsLimit)
      .then((data) => {
        console.log(data.data.clients.rows);
        setClients(data.data.clients.rows);
        setClientsTotal(data.data.clients.totalItems);
      })
      .catch((err) => console.log(err));
  }, [toggle]);

  useEffect(() => {
    setMultiParam({"page": clientsPage, "pageSize": clientsLimit});
  }, [clientsPage, clientsLimit])

  const viewClient = (e, id) => {
    e.preventDefault();
  };
  const approve = (e, id, isChange = false) => {
    e.preventDefault();
    approveClient(id, isChange)
      .then((data) => {
        setToggle(!toggle);
        setSelectDetails(null)
        setCurrentApproval(true)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const decline = (e, id) => {
    e.preventDefault();
    deleteClient(id)
      .then((data) => {
        setToggle(!toggle);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {}, [toggle]);
  return (
    <div className="p-5 shadow-md bg-white">
      <div className="grid grid-cols-6 gap-2">
        <h3
          className="col-span-5 text-lg uppercase tracking-wider font-bold"
          style={{ color: "#2EBAA3" }}
        >
          Client Approvals
        </h3>
        <input
          type="text"
          id="search"
          placeholder="Search"
          class="px-3 py-1.5 mr-2 mb-2 form-control block w-full text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
        />
      </div>
      <br />
      <div class="overflow-x-auto relative">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" class="py-3 px-6 uppercase">
                Sr.
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Company
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Contact Person
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Email ID
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Phone Number
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Website
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Address
              </th>
              <th scope="col" class="py-3 px-6 uppercase">
                Date
              </th>
              <th scope="col" class="py-3 px-6 uppercase text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {clients?.map((client, index) => {
              return (
                <tr class="bg-white border-b" key={index}>
                  <th
                    scope="row"
                    class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap cursor-pointer"
                    // onClick={(e) => viewCustomer(e, customer._id)}
                    key={index}
                  >
                    {index + 1}
                  </th>
                  <td class="py-4 px-6">
                    {!!client?.company ? "-" : client?.company}
                  </td>
                  <td class="py-4 px-6">
                    {client?.firstName + " " + client?.lastName}
                  </td>
                  <td class="py-4 px-6">{client?.email}</td>
                  <td class="py-4 px-6">{client?.phone == null ? '-' :client?.phone}</td>
                  <td class="py-4 px-6">
                    {client?.website == null ? "-" : client?.website}
                  </td>
                  <td class="py-4 px-6">
                    {client?.address == null ? "-" : client?.address}
                  </td>
                  <td class="py-4 px-6">
                    {client?.createdAt == null
                      ? "-"
                      : client?.createdAt.split("T")[0]}
                  </td>
                  <td class="py-4 px-6">
                    <div className="grid grid-cols-2 gap-2">
                      <Button
                        size="small"
                        variant="outlined"
                        // onClick={(e) => approve(e, client?.id)}
                        onClick={() => setSelectDetails(client?.id)}
                      >
                        Approve
                      </Button>
                      <Button
                        size="small"
                        style={{ color: "red", borderColor: "red" }}
                        variant="outlined"
                        onClick={(e) => decline(e, client?.id)}
                      >
                        Decline
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <TablePagination
        component="div"
        count={clientsTotal}
        page={clientsPage}
        onPageChange={handleChangePage}
        rowsPerPage={clientsLimit}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[2, 5, 10, 25, 50, 100]}
      />
      <MuiModal open={selectedDetails} onCancel={() => setSelectDetails(null)}>
        <Box sx={{ padding: 2 }}>
          <Typography variant="h6" gutterBottom>
            Confirm Approval
          </Typography>
          <Typography variant="body1" paragraph>
            Please ensure you have reviewed all the details of the { !currentApproval ? 'Vendor' : 'Client' } before proceeding with the approval. Once approved, this action cannot be undone.
          </Typography>
          <Typography variant="body1" paragraph>
            Do you want to proceed with the approval?
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 2 }}>
          <Button onClick={() => setSelectDetails(null)} variant="outlined">
              Cancel
            </Button>
            <Button onClick={(e) => setCurrentApproval(!currentApproval)} variant="contained" color="primary" sx={{ color: 'white' }}>
              Change Role
            </Button>
            <Button onClick={(e) => approve(e, selectedDetails, !currentApproval)} variant="contained" sx={{ background: '#0B85BA' }} color="secondary">
            Approve As { !currentApproval ? 'Vendor' : 'Client' }
            </Button>
          </Box>
        </Box>
      </MuiModal>

    </div>
  );
};

export default PendingClientList;
